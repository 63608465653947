<template>
  <div class="landing-wrapper height-100">
    <a-row type="flex" justify="space-around" align="middle" class="height-100">
      <a-col :xs="22" :sm="17" :md="12" :lg="12" :xl="12">
        <h1>
          Личный кабинет клиента <br />
          ТГК-2 Энергосбыт
        </h1>
        <h2>
          энергосбыт
          <div class="sphere"></div>
          интеллектуальная система учёта
        </h2>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
</script>